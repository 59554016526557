/**
 * Core
 */
import React from 'react'
import { useState, useEffect } from 'react'

/**
 * Components
 */
import Footer from '../components/footer'
import Bio from '../components/pages/about/bio'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'



const CertifiedJudges = () => {

    useScrollTrigger('.reveal');

     const [judges, setJudges] = useState([]);

     useEffect(() => {
         fetch('https://api.awintern.com/api/v1/judge_certifications/certified_judges')
         .then((res) => {
             return res.json(); 
         })
         .then((data) => {
            setJudges(data);
         })
         .catch((error) => {
             console.error('Error:', error); 
         });
    }, [])

    
    
    return (
        <>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Certified Judges</h1>
                </div>
            </section>

            <section className='bg-gray-100 text-ai-gray-900 py-16'>
                <div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-32 mt-32 reveal top'>                
                        {judges.map((judge, index) => (

                            <Bio key={index} props={{ title: `${judge.name}`, subtitle: `${judge.job_title}`, company: `${judge.organization}`, 
                            image: `https://awintern.com${judge.profile_image ? judge.profile_image : "/assets/img/user-solid.svg"}`, className: '!bg-white' }} />
                        ))}
                    </div>
                </div>
            </section>
            
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default CertifiedJudges


