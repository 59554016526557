/**
 * Core
 */
import { Link } from 'react-router-dom'

/**
 * Utils
 */
import { itemsWithHome } from '../config/menu'

const Footer = () => {
    return (
        <>
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-8 text-ai-gray-300'>
                <ul className='col-span-2 grid grid-cols-1 sm:grid-cols-2 gap-x-8'>
                    {
                        itemsWithHome.map((item, key) => <li key={key} className='pt-1'><a href={item.link} className='transition-all duration-300 hover:text-ai-gray-400'>{item.title}</a></li>)
                    }
                </ul>
                
                <div className='flex items-center gap-4 self-start'>
                    {/* <Link to="https://www.facebook.com/AwardsInter/" className='w-7'><img src={`/images/icons/facebook.svg`} /></Link> */}
                    <Link to="https://www.youtube.com/@awardsinternational4131" className='w-7' target="_blank" rel="noopener noreferrer"><img src={`/images/icons/youtube.svg`} /></Link>
                    <Link to="https://www.instagram.com/awards_international/" className='w-7' target="_blank" rel="noopener noreferrer"><img src={`/images/icons/instagram.svg`} /></Link>
                    {/* <Link to="https://www.twitter.com/awardsinter" className='w-7'><img src={`/images/icons/x.svg`} /></Link> */}
                    <Link to="https://www.linkedin.com/company/awards-international" className='w-7' target="_blank" rel="noopener noreferrer"><img src={`/images/icons/linkedin.svg`} /></Link>
                </div>
            </div>
            <div className='flex justify-center items-center py-8 text-ai-gray-300'>
                <ul className=''>
                    <li className=''><Link to='/terms-of-use' className='transition-all duration-300 hover:text-ai-gray-400'>Terms of Use</Link></li>
                    <li className='pt-1 pb-8'><Link to='/privacy-policy' className='transition-all duration-300 hover:text-ai-gray-400'>Privacy policy</Link></li>
                </ul>
                © Copyright by Awards International 2008-2025. All rights reserved
            </div>
        </>
    )
}

export default Footer